/*
 * Application global variables.
 */

//  Primeng viewports
$viewport-sm: 576px !default; // mobile phones
$viewport-md: 768px !default; // tablets
$viewport-lg: 1024px !default; // desktops
$viewport-xl: 1440px !default;

// For margins & paddings
$space-8: 8px;
$space-16: 16px;
$space-24: 24px;
$space-32: 32px;
$space-40: 40px;
$space-48: 48px;
$space-56: 56px;
$space-64: 64px;
$space-72: 72px;

// Height
$sidebar-item-height: 3rem;
$header-height: 6.25rem;
$header-height-small-screens: 4.5rem;
$sidebar-height-large-screens: 6rem;
$sidebar-height-small-screens: 4.5rem;
$footer-height: 6.25rem;
$logo-height: 3rem;

// Width
$sidebar-width: 15rem;
$sidebar-item-width: 14rem;

// Font
$root-font-family: "Source Sans Pro", sans-serif;
$root-font-size: 16px;
$table-header-font-size: 1rem;
$table-data-font-size: 0.875rem;

// Color
/* stylelint-disable */
$primary: rgb(var(--primary));
$primary-light: rgba(var(--primary), 0.5);
$primary-lighter: rgba(var(--primary), 0.2);
$danger: rgb(var(--danger));
$danger-light: rgba(var(--danger), 0.5);
$background: rgb(var(--background));
$shell-bg: rgb(var(--shell-bg));
$white: #fff;
$header-bg: rgb(var(--header-bg));
$primary-text-color: rgb(var(--primary-text-color));
$secondary-text-color: rgb(var(--secondary-text-color));
$gray: rgb(var(--gray));
$gray-light: rgba(var(--gray), 0.6);
$gray-lighter: rgba(var(--gray), 0.2);
$btn-color: $secondary-text-color;
$placeholder-color: rgba(var(--gray), 0.8);
$success: rgb(var(--success));
$success-light: rgba(var(--success), 0.5);
$warning: rgb(var(--warning));
$highlight: rgb(var(--highlight));
/* stylelint-enable */

// Shadow
$primary-shadow: 0 2px 19px 0 rgb(29 34 40 / 30%);
$secondary-shadow: 0 3px 14px 0 rgb(29 34 40 / 20%);
$focus-shadow: inset 0 0 0 1px $primary;

// Border radius
$border-radius: 2.5rem;

// Icon size
$icon-size: 1.5rem;

// Form
$input-height: 3.5rem;
$input-border-radius: 2rem;
$button-height: 3.5rem;
$button-border-radius: 28px;
$input-padding: 1rem 1.5rem;
$input-border: 2px solid $white;
$textarea-height: 10rem;

@mixin font-thin() {
  font-weight: 100;
}

@mixin font-light() {
  font-weight: 300;
}

@mixin font-regular() {
  font-weight: 400;
}

@mixin font-medium() {
  font-weight: 500;
}

@mixin font-semi-bold() {
  font-weight: 600;
}

@mixin font-bold() {
  font-weight: 700;
}

@mixin font-black() {
  font-weight: 900;
}
